import { CommonModule } from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterLink, RouterModule } from '@angular/router';
import { Component, effect, ElementRef, Input, ViewChild } from '@angular/core';

import { closeUnicode } from '@lib-core/constants/unicode-characters-contants';
import { capitalize, clear } from '@lib-core/helpers';
import { AUTH_ROUTE_NAMES, AuthService } from '@lib-auth/auth';
import { CustomerSelectModalComponent } from '@lib-shared/modules/customer/customer-select-modal/customer-select-modal.component';
import { CustomerModel, RepresentativeModel } from '@lib-core/models';
import { CustomerService, LoaderService, RouterService, SidebarService } from '@lib-core/services';
import { CartCountStore, CustomerStore, GalleryRequestStore, RepresentativeStore, UserStore } from '@lib-core/stores';
import { GALLERY_PRODUCT_SCROLL_POSITION_COOKIE_NAME } from '@lib-core/constants';

@Component({
	standalone: true,
	selector: 'shared-navbar',
	imports: [RouterLink, RouterModule, CommonModule, NgbTooltipModule, CustomerSelectModalComponent],
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
	cartCount = 0;
	authRoutes = AUTH_ROUTE_NAMES;
	closeUnicode = closeUnicode;

	currentCustomer: CustomerModel;
	currentRepresentative: RepresentativeModel;

	@Input()
	cartRoute: string = '/sacola';

	@Input()
	accountRoute: string = '/minha-conta';

	@Input()
	enableCart: boolean = true;

	@Input()
	enableMyAccount: boolean = true;

	@Input()
	enableSidebar: boolean = true;

	@ViewChild('customerListModal')
	customerListModal: ElementRef;

	constructor(
		private userStore: UserStore,
		private authService: AuthService,
		private customerStore: CustomerStore,
		private routerService: RouterService,
		private loaderService: LoaderService,
		private cartCountStore: CartCountStore,
		private sidebarService: SidebarService,
		private customerService: CustomerService,
		private representativeStore: RepresentativeStore,
		private galleryRequestStore: GalleryRequestStore,
	) {
		effect(() => {
			this.cartCount = this.cartCountStore.get() || 0;
		});

		effect(() => {
			this.currentCustomer = this.customerStore.get();
		});

		effect(() => {
			this.currentRepresentative = this.representativeStore.get();
		});
	}

	get username(): string {
		return this.userStore.getUsername();
	}

	get customerLabel(): string {
		if (this.currentCustomer) {
			const customerName = capitalize(this.currentCustomer.name);

			if (this.isRepresentative) {
				return customerName;
			}

			return `Olá, ${customerName}`;
		}

		return 'Selecionar cliente';
	}

	get customerTooltip(): string {
		return this.currentCustomer ? 'Trocar cliente' : 'Selecionar cliente';
	}

	get customerIcon(): string {
		if (this.currentCustomer) {
			return 'bi-person-check';
		}
		return 'bi-person-exclamation';
	}

	get isRepresentative(): boolean {
		return !!this.currentRepresentative?.code;
	}

	get showRemoveCustomerButton(): boolean {
		return this.currentCustomer && this.isRepresentative;
	}

	get currentUrl(): { [key: string]: string } {
		return { redirectUrl: this.routerService.getCurrentUrl() };
	}

	selectCustomer(): void {
		this.customerService.openCustomerSelectModal(this.customerListModal);
	}

	removeCustomer(): void {
		if (this.isRepresentative) {
			this.loaderService.start();
			setTimeout(() => {
				this.customerStore.clear();
				this.cartCountStore.clear();
				this.routerService.reload();
			}, 200);
		}
	}

	clearFilters() {
		clear(GALLERY_PRODUCT_SCROLL_POSITION_COOKIE_NAME);
		this.galleryRequestStore.clear();
	}

	toggleSidebar(): void {
		if (!this.enableSidebar) {
			return;
		}
		this.sidebarService.toggle();
	}

	logout() {
		this.authService.logout();
	}
}
