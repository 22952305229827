<div class="row" *ngIf="product">
	<div class="col-24">
		<shared-page-title [title]="product.description" [subtitle]="'Código: ' + product.code" />
	</div>

	<div class="col-24 col-md-9 col-lg-6">
		<div class="mb-4">
			<app-product-image [height]="240" [imageList]="imageList" [url]="currentImage" [enableZoom]="true" />
		</div>

		<app-vertical-product-carousel [height]="70" classList="mb-4" [imageList]="imageList" (select)="handleProductClicked($event)" />

		<core-group-box classList="mb-4">
			<ng-template #body>
				<div class="d-flex justify-content-between">
					<span class="form-label mb-0">Quantidade</span>
					<span class="form-label mb-0">{{ quantity }}</span>
				</div>
				<div class="d-flex justify-content-between">
					<span class="form-label mb-0">Valor bruto</span>
					<span class="form-label mb-0">{{ currentSku?.price | brl }}</span>
				</div>
				<div class="d-flex justify-content-between">
					<span class="form-label mb-0"
						>Valor líquido
						<span class="bi bi-info-circle-fill opacity-75" [ngbTooltip]="discountsTooltip"></span>
					</span>
					<span class="form-label mb-0 text-success fs-6">{{ skuNetPrice | brl }}</span>
				</div>
				<hr />
				<h6 class="fw-semibold">Total</h6>
				<div class="d-flex justify-content-between">
					<span class="form-label mb-0">Quantidade</span>
					<span class="form-label mb-0">{{ quantityTotal }}</span>
				</div>
				<div class="d-flex justify-content-between">
					<span class="form-label mb-0">Valor bruto</span>
					<span class="form-label mb-0">{{ totalValue | brl }}</span>
				</div>
				<div class="d-flex justify-content-between">
					<span class="form-label mb-0">Valor líquido</span>
					<span class="form-label mb-0 text-success fs-6">{{ totalValueWithDiscount | brl }}</span>
				</div>
			</ng-template>
		</core-group-box>
	</div>

	<div class="col-24 col-md-15 col-lg-18">
		<core-group-box classList="mb-4" bodyClassList="p-0">
			<ng-template #header> Digitação da Grade </ng-template>
			<ng-template #body>
				<div class="overflow-auto py-0 px-1" [style.height]="'520px'">
					<table class="table table-sm mb-2" aria-describedby="tabela de grade de produto">
						<thead>
							<tr>
								<th scope="col" class="w-100" [style.min-width]="'240px'"></th>
								@for (size of sizeList; track size) {
								<th scope="col" class="text-center">{{ size }}</th>
								}
							</tr>
						</thead>
						<tbody>
							@for (color of colorList; track color.code) {
							<tr *ngIf="hasColorStock(color.code)">
								<td class="fw-semibold" [style.height]="'50px'">
									<div class="d-flex align-items-center h-100" (click)="updateProductImage(color.code)" (keyup)="updateProductImage(color.code)">
										<app-color-sample [rgb]="color.rgb" size="lg" [sampleUrl]="color.sampleUrl" [disableSelection]="true" />
										<div class="mx-2 my-1">
											<p class="m-0">{{ color.description }}</p>
											<p class="m-0">{{ color.code }}</p>
										</div>
									</div>
								</td>
								@for (size of sizeList; track size) {
								<td class="text-center">
									<input
										#quantity
										type="number"
										class="form-control text-center form-control-sm"
										[id]="getGradeName(color.code, size)"
										[style.width]="'50px'"
										[class.border-primary]="findValue(color.code, size)"
										(change)="changeQuantity($event, color.code, size)"
										(focus)="updateValueField(color.code, size)"
										(keyup)="changeQuantity($event, color.code, size)"
										[disabled]="!enableInputField(color.code, size)" />
									<div [ngbTooltip]="getStockLabel(color.code, size)">
										<p class="m-0">{{ getStockLabel(color.code, size, true) }}</p>
									</div>
								</td>
								}
							</tr>
							}
						</tbody>
					</table>
				</div>
			</ng-template>
		</core-group-box>
	</div>
	<div class="position-sticky bottom-0 p-2">
		<div class="row">
			<div class="col-24 col-md-15 col-lg-18 offset-md-9 offset-lg-6 bg-white">
				<div class="d-flex justify-content-end gap-2">
					<button class="btn btn-secondary" (click)="cancelAction()">
						<i class="bi bi-x-lg"></i>
						Cancelar
					</button>
					<div [ngbTooltip]="actionButtonTooltip">
						<button class="btn btn-success" (click)="addOrUpdateCart()" [disabled]="!enableActionButton">
							<i class="bi bi-cart"></i>
							{{ actionButtonLabel }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-24 col-md-15 col-lg-18 offset-md-9 offset-lg-6">
		<app-product-details-tab [product]="product" />
	</div>
</div>

<ng-template #discountsTooltip>
	<p class="m-0" *ngFor="let discount of discountList">{{ discount.description }}: {{ discount.percent }} %</p>
</ng-template>
