<app-custom-collapse [collapsed]="collapse" *ngIf="isValid">
	<ng-template #header>
		{{ label }}
	</ng-template>
	<ng-template #body>
		<div [class]="classList" class="my-2">
			<div class="gallery-filter-group__content">
				@for (item of entityList; track item.code) {
				<div class="gallery-filter-group__body">
					<div class="gallery-filter-group__sample" *ngIf="hasSample">
						<app-color-sample [rgb]="item.rgb" [sampleUrl]="item.sampleUrl"></app-color-sample>
					</div>
					<input
						[id]="item.code"
						type="checkbox"
						class="form-check-input ms-1 m-0"
						[value]="item.code"
						[checked]="isItemSelected(item.code)"
						(change)="onStateChange(item.code, $any($event.target)?.checked)"
					/>
					<label class="form-check-label text-truncate" [for]="item.code">
						{{ getItemDescription(item) }}
					</label>
				</div>
				}
			</div>
		</div>
	</ng-template>
</app-custom-collapse>
