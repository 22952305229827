import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import {
	NgbModal,
	NgbDateAdapter,
	NgbDropdownModule,
	NgbDatepickerI18n,
	NgbAccordionModule,
	NgbDatepickerModule,
	NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CustomAdapter, CustomDateParserFormatter, CustomDatepickerI18n, I18n } from '@lib-core/providers';

import { formatDate } from '@lib-core/helpers';
import { ImagesService } from '@lib-core/services/images.service';
import { OrderStatusModel } from '@lib-core/models/order-status.model';
import { CART_ROUTE_NAMES } from '@proj-b2b/app/modules/cart/cart.constants';
import { printModalContent } from '@lib-core/helpers';
import { CartItemRequestDto } from '@lib-core/dtos';
import { PageTitleComponent } from '@lib-shared/components';
import { ProductImageComponent } from '@proj-b2b/app/components/product-image/product-image.component';
import { map, Observable, Subscription } from 'rxjs';
import { CartProductItemTableComponent } from '@proj-b2b/app/modules/cart/components/cart-product-item-table/cart-product-item-table.component';
import { CurrencyBrlPipe, DateShortPipe } from '@lib-core/pipes';
import { CustomerStore, ModalityStore, RepresentativeStore } from '@lib-core/stores';
import { GroupBoxComponent, LabelValueComponent } from '@lib-core/components';
import { CartService, SalesOrderService, ToastService } from '@lib-core/services';
import { getDataTableOptions, getDataTableOrderByIndex } from '@lib-core/config';
import { CartModel, CustomerModel, ProductModel, SalesOrderModel, SalesOrderResponseModel } from '@lib-core/models';

@Component({
	imports: [
		CommonModule,
		DateShortPipe,
		CurrencyBrlPipe,
		DataTablesModule,
		NgbDropdownModule,
		NgbAccordionModule,
		PageTitleComponent,
		LabelValueComponent,
		ReactiveFormsModule,
		NgbDatepickerModule,
		GroupBoxComponent,
		ProductImageComponent,
		CartProductItemTableComponent,
	],
	providers: [
		I18n,
		{ provide: NgbDateAdapter, useClass: CustomAdapter },
		{ provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
		{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
	],
	standalone: true,
	selector: 'lib-shared-account-orders',
	templateUrl: './account-orders.component.html',
})
export class AccountOrdersComponent implements OnInit, OnDestroy {
	submitted = false;

	filterForm!: FormGroup;
	salesOrder: SalesOrderModel;
	orderDetail: SalesOrderModel;
	salesOrderResponse: SalesOrderResponseModel;
	salesOrderStatusList: OrderStatusModel[] = [];
	salesOrderList: any;

	customer: CustomerModel | any;
	dtOptions = { ...getDataTableOptions(), order: getDataTableOrderByIndex(1) };

	numberOfItems = 0;
	isAccordionClosed = true;
	isDownloadAllColors = false;

	orderProductsItems: any;
	orderProductsImages: any[] = [];
	orderProductsSubItems: any[] = [];

	product: ProductModel;
	subscriptions: Subscription[] = [];

	@ViewChild('orderDetails')
	orderDetailsModal: ElementRef;

	@ViewChild('clearCartModal')
	clearCartModal: ElementRef;

	@ViewChild('modalityNullModal')
	modalityNullModal: ElementRef;

	@ViewChild('modalitySwitchModal')
	modalitySwitchModal: ElementRef;

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private cartService: CartService,
		private modalService: NgbModal,
		private toastService: ToastService,
		private modalityStore: ModalityStore,
		private customerStore: CustomerStore,
		private imagesService: ImagesService,
		private salesOrderService: SalesOrderService,
		private representativeStore: RepresentativeStore,
	) {
		this.customer = this.customerStore.get();
	}

	ngOnInit() {
		this.registerForm();
		this.initDates();
		this.queryOrders();
		this.queryOrderStatus();
	}

	ngOnDestroy() {
		this.salesOrder = null;
		this.orderDetail = null;

		this.product = null;
		this.customer = null;
		this.orderProductsItems = [];
		this.orderProductsImages = [];
		this.orderProductsSubItems = [];

		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	get f() {
		return this.filterForm.controls;
	}

	get isSendToCartDisabled() {
		return this.orderDetail.e_ORDEM_VENDA_SUB_ITEM?.length === 0 || !this.customerStore.isValid();
	}

	registerForm() {
		this.filterForm = this.fb.group({
			dateFrom: ['', Validators.required],
			dateTo: ['', Validators.required],
		});
	}

	initDates() {
		const today = new Date();
		const monthAgo = new Date(today);
		monthAgo.setMonth(today.getMonth() - 2);

		this.filterForm.patchValue({
			dateFrom: formatDate(monthAgo),
			dateTo: formatDate(today),
		});
	}

	onFilter() {
		this.submitted = true;
		if (this.filterForm.invalid) return;
		this.queryOrders();
	}

	queryOrders() {
		this.salesOrderList = null;
		this.salesOrderResponse = null;

		const representativeCode = this.representativeStore.get()?.code;
		const customerCode = this.customerStore.get()?.code;
		const { dateFrom, dateTo } = this.filterForm.value;

		this.salesOrderService.queryOrders(customerCode, dateFrom, dateTo, representativeCode).subscribe({
			next: response => {
				if (response.success) {
					this.salesOrderResponse = response.data;
					this.salesOrderList = response.data?.e_ORDEM_VENDA || [];
					return;
				}
				var error = 'Ocorreu um erro inesperado. Tente novamente mais tarde.';
				this.showQueryWarningToast(error);
			},
			error: error => this.showQueryErrorToast(error.message),
		});
	}

	detailOrderItens(order: string): Observable<any> {
		return this.salesOrderService.detailOrderItens(order, false).pipe(
			map(response => {
				if (response.success) {
					this.orderDetail = response.data;
					this.orderProductsImages = response.data.productsList;
				}
				return response;
			}),
		);
	}

	checkIfDownloadAllColors() {
		this.isDownloadAllColors = true;
		this.downloadImagesZip(this.salesOrder);
	}

	downloadImagesZip(salesOrder: SalesOrderModel) {
		this.salesOrderService.detailOrderItens(salesOrder.cD_DOCUMENTO, this.isDownloadAllColors).subscribe(response => {
			try {
				this.orderDetail = response.data;
				this.orderProductsImages = response.data.productsList;
				this.imagesService.downloadImagesZip(this.orderProductsImages, salesOrder.cD_DOCUMENTO, this.isDownloadAllColors);
				this.isDownloadAllColors = false;
			} catch (error) {
				this.showQueryErrorToast(error.toString());
			}
		});
	}

	sendOrderToCart() {
		if (this.isSendToCartDisabled) {
			return;
		}

		this.sendOrderToCartCheckModality();
	}

	sendOrderToCartCheckModality() {
		var currentModality = this.modalityStore.get()?.code;

		// TODO: Precisa alinhar com a malwee para retornar a modalidade da requisição de detalhes do pedido
		// Depois remover a abaixo
		this.orderDetail.cD_MODALIDADE = '000030';

		// E liberar das linhas 229 à 232
		// if (!this.orderDetail.cD_MODALIDADE) {
		// 	this.modalService.open(this.modalityNullModal, { centered: true }).result;
		// 	return;
		// }

		if (this.orderDetail.cD_MODALIDADE == currentModality) {
			this.sendOrderToCartCheckCart();
			return;
		}

		this.modalService.open(this.modalitySwitchModal, { centered: true, size: 'lg' }).result.then(
			() => {
				this.modalityStore.setByProp(this.orderDetail.cD_MODALIDADE);
				this.sendOrderToCartCheckCart();
			},
			() => {},
		);
	}

	sendOrderToCartCheckCart() {
		this.cartService.getCart().subscribe({
			next: getResponse => {
				var cart = getResponse.data;

				if (!cart || cart.cartItemList?.length == 0) {
					this.sendOrderToCartPrepareItems(cart);
					return;
				}

				this.modalService.open(this.clearCartModal, { centered: true, size: 'lg' }).result.then(
					() => {
						this.cartService.deleteCurrentCart().subscribe({
							next: deleteResponse => {
								if (deleteResponse?.success) {
									this.sendOrderToCartPrepareItems(cart);
									return;
								}
								this.toastService.danger('Não foi possível limpar seu carrinho: ' + deleteResponse.message);
							},
							error: () => {
								this.toastService.danger('Não foi possível limpar seu carrinho, por favor tente novamente');
							},
						});
					},
					() => {},
				);
			},
			error: () => {
				this.toastService.danger('Erro ao buscar carrinho, por favor tente novamente');
			},
		});
	}

	sendOrderToCartPrepareItems(cart: CartModel) {
		const items = [] as CartItemRequestDto[];

		this.orderDetail.e_ORDEM_VENDA_SUB_ITEM.forEach(product => {
			items.push({
				cartId: cart?.id ?? '',
				quantity: product.qtD_SUB_ITEM,
				sizeCode: product.tamanho,
				colorCode: product.cor,
				productCode: product.cD_MATERIAL,
			} as CartItemRequestDto);
		});

		this.cartService.addItems(items, true).subscribe({
			next: response => {
				if (response.success) {
					this.modalService.dismissAll();
					this.router.navigate([CART_ROUTE_NAMES.details.url]);
					return;
				}
				this.toastService.danger('Erro ao adicionar produtos ao carrinho: ' + response.message);
			},
			error: () => {
				this.toastService.danger('Erro ao adicionar produtos ao carrinho, por favor tente novamente');
			},
		});
	}

	clearCart() {
		this.cartService.clearItems().subscribe({});
	}

	detailOrder(orderCode: string): Observable<any> {
		return this.salesOrderService.detailOrder(orderCode).pipe(
			map(response => {
				if (response.success) {
					this.orderDetail = response.data;
					this.orderProductsItems = response.data.e_ORDEM_VENDA_ITEM;
					this.customer = response.data.customer;
					this.orderProductsSubItems = response.data.e_ORDEM_VENDA_SUB_ITEM;
					this.checkDuplicateItensOnList();
					this.numberOfItems = this.orderProductsItems.length;
					return response;
				}
				this.showQueryErrorToast(response.message);
				return response;
			}),
		);
	}

	checkDuplicateItensOnList() {
		const uniqueItems = new Map<string, any>();
		this.orderProductsItems.forEach((item: { cD_MATERIAL: string }) => {
			if (!uniqueItems.has(item.cD_MATERIAL)) {
				uniqueItems.set(item.cD_MATERIAL, item);
			}
		});
		this.orderProductsItems = Array.from(uniqueItems.values());
	}

	queryOrderStatus() {
		if (this.salesOrderStatusList.length) return;

		this.salesOrderService.listStatus().subscribe({
			next: response => {
				if (response.success) {
					this.salesOrderStatusList = response.data;
				}
			},
			error: error => this.showOrderStatusErrorToast(error.message),
		});
	}

	getStatusDescription(status: string) {
		return this.salesOrderStatusList.find(s => s.cD_STATUS === status)?.descricao;
	}

	showOrderDetailsModal(salesOrder: SalesOrderModel) {
		this.salesOrder = salesOrder;

		this.detailOrder(salesOrder.cD_DOCUMENTO).subscribe({
			next: () => {
				this.modalService.open(this.orderDetailsModal, { size: 'xl', centered: true }).result.then(
					() => {},
					() => {
						this.clearOrderInformation();
					},
				);
			},
			error: error => {
				this.showQueryErrorToast(error.message);
			},
		});
	}

	clearOrderInformation() {
		this.orderProductsImages = [];
		this.isAccordionClosed = true;
		this.modalService.dismissAll();
	}

	showQueryErrorToast(message: string) {
		this.toastService.danger(`Erro ao processar pedido: ${message}`);
	}

	showQueryWarningToast(message: string) {
		this.toastService.warning(`${message}`);
	}

	showOrderStatusErrorToast(message: string) {
		this.toastService.danger('Erro ao buscar status de pedidos: ' + message);
	}

	toggleAccordion() {
		if (this.isAccordionClosed) {
			if (this.orderProductsImages.length === 0) {
				this.detailOrderItens(this.salesOrder.cD_DOCUMENTO).subscribe();
			}
		}
		this.isAccordionClosed = !this.isAccordionClosed;
	}

	getImageUrlFromProduct(product: string) {
		const foundItem = this.orderProductsImages.find(image => image.material == product);
		return foundItem ? foundItem.imageUrl : null;
	}

	uniqueSizes(materialCode: string): string[] {
		const sizes = [...new Set(this.orderProductsSubItems.filter(item => item.cD_MATERIAL === materialCode).map(item => item.tamanho as string))];
		return sizes
			.map(size => (isNaN(Number(size)) ? size : Number(size)))
			.sort((a, b) => (typeof a === 'number' && typeof b === 'number' ? a - b : String(a).localeCompare(String(b))))
			.map(size => String(size));
	}

	uniqueColors(materialCode: string): string[] {
		return [...new Set(this.orderProductsSubItems.filter(item => item.cD_MATERIAL === materialCode && item.qtD_SUB_ITEM > 0).map(item => item.cor as string))];
	}

	getQuantityByColorAndSize(materialCode: string, color: string, size: string): number {
		return this.orderProductsSubItems.find(item => item.cD_MATERIAL === materialCode && item.cor === color && item.tamanho === size)?.qtD_SUB_ITEM || 0;
	}

	getTotalBySize(materialCode: string, size: string): number {
		return this.orderProductsSubItems
			.filter(item => item.cD_MATERIAL === materialCode && item.tamanho === size)
			.reduce((sum, item) => sum + item.qtD_SUB_ITEM, 0);
	}

	printModalContent() {
		var modalContent = document.querySelector('.modal-content .modal-body')!.innerHTML;
		printModalContent(modalContent);
	}
}
