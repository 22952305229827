import { Injectable } from '@angular/core';
import { Observable, map, take } from 'rxjs';

import { AuthStore } from '@lib-auth/auth';
import { CartResource } from '@lib-core/resources';
import { ToastService } from '@lib-core/services';
import { ApiResponseModel, CartModel } from '@lib-core/models';
import { CartCommercialRulesRequestDto, CartItemRequestDto, CartRequestDto } from '@lib-core/dtos';
import { CartStore, CustomerStore, ModalityStore, RepresentativeStore, SalesPeriodStore, SalesTeamStore } from '@lib-core/stores';

@Injectable({
	providedIn: 'root',
})
export class CartService {
	constructor(
		private cartStore: CartStore,
		private authStore: AuthStore,
		private cartResource: CartResource,
		private customerStore: CustomerStore,
		private modalityStore: ModalityStore,
		private toasterService: ToastService,
		private salesTeamStore: SalesTeamStore,
		private salesPeriodStore: SalesPeriodStore,
		private representativeStore: RepresentativeStore,
	) {}

	getCart(loadProductDetails = false, validateStock = false): Observable<ApiResponseModel<CartModel>> {
		return this.cartResource
			.getCart({
				...this.createRequest(),
				loadProductDetails,
				validateStock,
			})
			.pipe(
				map(response => {
					if (!response?.success) {
						this.toasterService.danger(response.message);
						return response;
					}

					if (response.data) {
						this.cartStore.set(response.data);
						return response;
					}

					this.cartStore.clear();
					return response;
				}),
			);
	}

	deleteCurrentCart() {
		return this.cartResource.deleteCart(this.createRequest()).pipe(
			map(response => {
				if (!response?.success) {
					this.toasterService.danger(response.message);
					return response;
				}

				this.cartStore.clear();
				return response;
			}),
		);
	}

	addItems(items: CartItemRequestDto[], replaceItems = false) {
		return this.cartResource.addItems({ ...this.createRequest(), cartItems: items, replaceItems }).pipe(take(1));
	}

	removeItems(items: CartItemRequestDto[]) {
		return this.cartResource
			.removeItems({ ...this.createRequest(), cartItems: items })
			.pipe(take(1))
			.pipe(map(() => this.getCart(true, true).pipe(take(1)).subscribe()));
	}

	clearItems() {
		return this.cartResource.clearItems({ ...this.createRequest() }).pipe(take(1));
	}

	getCommercialRules() {
		return this.cartResource.getCommercialRules(this.createRequest());
	}

	updateCommercialRules(payload: CartCommercialRulesRequestDto) {
		return this.cartResource.updateCommercialRules(payload);
	}

	processCart(cartId: string) {
		return this.cartResource.processCart(cartId).pipe(
			map(response => {
				if (!response?.success) {
					this.toasterService.danger(response.message);
					return response;
				}

				if (response.data) {
					this.cartStore.set(response.data);
					return response;
				}

				this.cartStore.clear();
				return response;
			}),
		);
	}

	finishCart(cartId: string) {
		return this.cartResource.finishCart(cartId).pipe(take(1));
	}

	createRequest(): CartRequestDto {
		const response = {
			username: this.authStore.get().username,
			modalityCode: this.modalityStore.get()?.code,
			customerCode: this.customerStore.get()?.code,
			salesTeamCode: this.salesTeamStore.get()?.code,
			salesPeriodCode: this.salesPeriodStore.get()?.code,
			representativeCode: this.representativeStore.get()?.code,
		} as CartRequestDto;

		return response;
	}
}
