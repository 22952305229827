<div ngbAccordion [class]="classList" class="border-0">
	<div class="border-0" ngbAccordionItem [collapsed]="collapsed">
		<h2 ngbAccordionHeader>
			<button ngbAccordionButton class="border-0 shadow-none p-2">
				<p class="m-0 fw-medium">
					<ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
				</p>
			</button>
		</h2>
		<div ngbAccordionCollapse>
			<div ngbAccordionBody class="p-0" [class]="bodyClassList">
				<ng-template>
					<ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
				</ng-template>
			</div>
		</div>
	</div>
</div>
